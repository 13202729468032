<template lang="pug">
Navbar
.padding-top
  HeadingLayout(:title="title", :desc="desc")
.container
  .row.align-items-center.mb-md-5
    .px-4.py-2
      .row.align-items-center.g-5
        .col-md-6
          .fs-4.fw-bold.lh-1.mb-3 Kloser helps to expand your relationship
          .fs-6.lead.fw-normal Customer relationship management isn’t just for big businesses. Create stronger connections from day 1 with our Marketing CRM tools, and find everything you need to have relevant conversations with your people—whether you’re building a brand new audience or you have thousands of contacts already.

        .col-md-6
            img.d-block.mx-lg-auto.img-fluid(src="images/about.png")
.bg-background.py-5
  .container
    .row.text-center
      .fs-3.fw-bold.lh-sm.pb-2.text-center Our leadership team
      template(v-for="team in teams")
        .col-md-3.py-3.py-md-5
          .person-profile.mb-4
            img.w-50(:src="'/images/team' + team.image")
          .person-name.fw-bold  {{ team.name}}
          .person-position {{ team.position }}
Footer
</template>

<script>
import Navbar from "../components/TheNavbar";
import Footer from "../components/TheFooter";
import HeadingLayout from "../layout/HeadingLayout";
export default {
  name: "About",
  components: { HeadingLayout, Footer, Navbar },
  data() {
    return {
      title: "About us",
      desc: "Radically improving lead management",
      teams: [
        {
          image: "/patrick.png",
          name: "Patrick Heng",
          position: "Founder",
        },
        {
          image: "/lum.png",
          name: "Chong Kong Lum",
          position: "Co-founder",
        },
        {
          image: "/kuan.png",
          name: "Kuan Yew",
          position: "CEO",
        },
        {
          image: "/jeevan.png",
          name: "Jeevan",
          position: "Chief Technology Officer",
        },
        {
          image: "/Irene.png",
          name: "Irene Lew",
          position: "CDO",
        },
      ],
    };
  },
};
</script>
